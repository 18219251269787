import { Routes } from '@angular/router';
import { AuthorizationGuard } from '@shared/authorization.guard';

export const routes: Routes = [
    { path: '', redirectTo: 'login', pathMatch: 'full' },
    { path: 'clientportal', loadChildren: () => import('../app/client-portal/client-portal.module').then(x => x.ClientPortalModule) },
    { path: 'login', loadChildren: () => import('../app/login.module').then(x => x.LoginModule) },
    { path: 'fiserv/login', loadChildren: () => import('../app/login.module').then(x => x.LoginModule) },
    { path: 'exchangelogin', loadChildren: () => import('../app/exchange-login.module').then(x => x.ExchangeLoginModule) },
    { path: 'multifactorauthentication', loadChildren: () => import('../app/multi-factor-authentication.module').then(x => x.MultiFactorAuthenticationModule) },
    { path: 'forcechangepassword', loadChildren: () => import('../app/force-change-password.module').then(x => x.ForceChangePasswordModule) },
    {
        path: 'auth',
        loadChildren: () => import('../app/shared/authorized.module').then(x => x.AuthorizedModule),
        canActivate: [AuthorizationGuard]
    },
    { path: 'oauth', loadChildren: () => import('../app/shared/oauth/oauth.module').then(x => x.OAuthModule) },
    { path: 'appaccessv2', loadChildren: () => import('../app/app-access/app-access.module').then(x => x.AppAccessModule) },
    { path: '**', redirectTo: 'login' },
];
